/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useEventsStore} from './core/eventsStore'
import {EventOptions} from './EventSinglePartials/EventOptions'
import {useNavigate, useParams} from 'react-router-dom'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import {eventStatusLabel} from '../../../_helpers/_templateHelpers'
import Swal from 'sweetalert2'
import {EventPieCard} from './EventSinglePartials/EventPieCard'
import EventThumbnail from '../../../_components/EventThumbnail'
import EventColorCode from '../../../_components/EventColorCode'
import {trimCharacters} from '../../../_helpers/_helpers'
import {EventSalesOrBookingsCard} from './EventSinglePartials/EventSalesOrBookingsCard'
import {BreadcrumbModel, useAppSettingsStore} from '../settings/core/appSettingsStore'

const SingleEventPage: FC = () => {
  const {getEvent, currentEvent, deleteEvent} = useEventsStore()
  const {eventId} = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const {setBreadcrumbs, setCurrentPage} = useAppSettingsStore()

  useEffect(() => {
    // if current event is not set
    if (eventId) {
      setIsLoading(true)
      getEvent(eventId).finally(() => {
        setIsLoading(false)
      })
    }
    // eslint-disable-next-line
  }, [])

  // SEtup Breadcrumbs
  useEffect(() => {
    const crumbs: BreadcrumbModel[] = [
      {
        object: 'Events',
        title: 'All',
        link: '/events',
      },
      {
        object: 'Event',
        title: trimCharacters(currentEvent.name, 12),
        link: `/events/${currentEvent.id}`,
      },
    ]
    setBreadcrumbs(crumbs)
    setCurrentPage('Event')

    // eslint-disable-next-line
  }, [currentEvent])

  const handleOptionClick = (option: string) => {
    if (option === 'delete') {
      if (currentEvent.stats.bookingsCount > 0) {
        Swal.fire({
          title: 'Cannot delete event',
          text: 'Event has bookings and cannot be deleted',
          icon: 'error',
        })
      } else {
        Swal.fire({
          title: 'Are you sure?',
          text: 'Event will be deleted and cannot be recovered',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it',
        }).then((result) => {
          if (result.isConfirmed) {
            deleteEvent(currentEvent.id || '')
              .catch(() => {
                Swal.fire('Error', 'Could not delete event', 'error')
              })
              .then(() => {
                Swal.fire('Deleted!', 'Event has been deleted.', 'success').then(() => {
                  navigate('/events')
                })
              })
          }
        })
      }
    }
  }

  return (
    <>
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          {/* HEADERS */}

          <div className='page-title p-5 d-flex flex-column'>
            <div className='d-flex justify-content-between'>
              <div className='d-flex flex-row'>
                <EventThumbnail event={currentEvent} size={50} />
                <div className='d-flex flex-column'>
                  <h3 className='fs-1 fw-bolder mb-0'>{currentEvent.name}</h3>
                  <div className='d-flex align-items-start'>
                    <EventColorCode event={currentEvent} className='justify-content-start mt-1' />
                    <span className='text-muted'>{eventStatusLabel(currentEvent)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* begin::Row */}
          <div className='d-flex w-100 flex-lg-nowrap flex-column-reverse flex-lg-row'>
            {/* begin::Col */}
            <div className='d-flex flex-grow-1'>
              <EventOptions event={currentEvent} onClick={handleOptionClick} />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='d-flex flex-column align-items-start w-lg-80'>
              <EventPieCard className='m-3 me-10 ms-0 w-100 w-lg-450px' />
              <EventSalesOrBookingsCard
                className='m-3 w-100 me-10 ms-0 w-lg-450px'
                chartType='bookings'
                chartColor='warning'
              />
              {/* <ListsWidget26 className='h-lg-50' /> */}
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </>
      )}
    </>
  )
}

export {SingleEventPage}
