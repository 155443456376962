/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {ActualTicketModel, BookingTicketModel, useBookingStore} from '../core/bookingsStore'
import {BookingTicketItem} from './BookingTicketItem'
import {PageLoadingSpinner} from '../../../../_components/PageLoadingSpinner'
import {Modal} from 'react-bootstrap'
import {Field, Form, Formik} from 'formik'
import {KTIcon} from '../../../../_metronic/helpers'
import SubmitButton from '../../../../_components/SubmitButton'
import PhoneField from '../../../../_components/PhoneField'
import toast, {Toaster} from 'react-hot-toast'
import {PaginationControl} from 'react-bootstrap-pagination-control'

export function BookingTickets() {
  const {currentBooking, getBookingTickets, bookingTicketsPagination} = useBookingStore()
  const [isLoading, setIsLoading] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const {shareBookingTicket} = useBookingStore()
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [actualTicket, setActualTicket] = useState<ActualTicketModel>()

  /**
   * Load Booking Tickets
   */
  useEffect(() => {
    if (currentBooking.id) {
      setIsLoading(true)
      getBookingTickets(currentBooking.id, {}).finally(() => {
        setIsLoading(false)
      })
    } else {
      setIsLoading(false)
    }

    // eslint-disable-next-line
  }, [currentBooking.id, currentBooking.status])

  const handleCloseShareModal = () => {
    setShowShareModal(false)
  }

  const handleSubmitShareModal = async (values: any) => {
    try {
      setIsFormSubmitting(true)

      // make sure bookingId is available
      if (!currentBooking.id) {
        setIsFormSubmitting(false)
        return
      }

      await shareBookingTicket(
        currentBooking.id,
        values.ticketId,
        values?.email || values?.phone,
        values.recipientName
      )
      await getBookingTickets(currentBooking.id, {})

      setIsFormSubmitting(false)
      setShowShareModal(false)
      toast.success('Ticket shared successfully')
    } catch (err) {
      setIsFormSubmitting(false)
      console.error(err)
      toast.error(
        'Something went wrong. Please try again.  Make sure the phone number or email address are correctly formatted.'
      )
    }
  }

  const handleShare = (bt: BookingTicketModel, at: ActualTicketModel) => {
    setActualTicket(at)
    setShowShareModal(true)
  }

  const handlePaginationChange = async (page: number) => {
    if (!currentBooking.id) return
    setIsLoading(true)
    await getBookingTickets(currentBooking.id, {page})
    setIsLoading(false)
  }

  return (
    <>
      <Toaster />
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          <div className='d-flex flex-wrap align-items-center mb-6 p-2'>
            <h3 className='fw-bolder my-2'>My Tickets</h3>
            <span className='fs-6 text-gray-400 fw-bold ms-1'>{` / ${
              currentBooking && currentBooking.stats && currentBooking.stats.ticketsCount
            } Tickets`}</span>
          </div>

          <div className='row g-6 g-xl-9 px-2'>
            {currentBooking.bookingTickets.map((bookingTicket, index) => (
              <BookingTicketItem
                key={bookingTicket.ticket.id}
                event={currentBooking.event}
                bookingTicket={bookingTicket}
                enableSharing={true}
                onShare={handleShare}
              />
            ))}
          </div>
          {bookingTicketsPagination.totalPages > 1 && (
            <div className='row mt-5'>
              {/* pagination */}
              <PaginationControl
                page={bookingTicketsPagination.page}
                total={bookingTicketsPagination.totalResults}
                limit={bookingTicketsPagination.limit}
                changePage={handlePaginationChange}
              />
            </div>
          )}
        </>
      )}

      {/* modal to share ticket */}
      <Modal
        show={showShareModal}
        onHide={handleCloseShareModal}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>Share Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              ticketId: actualTicket?.id,
              email: '',
              phone: '',
              recipientName: '',
            }}
            enableReinitialize={true}
            onSubmit={handleSubmitShareModal}
          >
            {({errors, isValid, values, handleChange}) => (
              <Form className='form' noValidate={true}>
                <div className='d-flex flex-column'>
                  {actualTicket?.sentTo && (
                    <>
                      <div className='mb-5'>
                        <KTIcon
                          iconName='information-3'
                          iconType='solid'
                          className='fs-4 me-1 text-warning'
                        />
                        This ticket has already been shared with:
                        <strong>{actualTicket?.sentTo?.name}</strong>. You can re-share it with
                        another person, but note that it will only work according to the checkpoints
                        loaded.
                      </div>
                      <div className='separator separator-dashed border-secondary mt-3 mb-7'></div>
                    </>
                  )}

                  {/* create navbar tabs for each option */}
                  <ul className='nav nav-pills mb-10' role='tablist'>
                    <li className='nav-item'>
                      <a
                        className='nav-link active'
                        id='kt_modal_share_sms_tab'
                        data-bs-toggle='tab'
                        href='#kt_modal_share_sms'
                        onClick={() => handleChange({target: {name: 'email', value: ''}})}
                        role='tab'
                      >
                        Share By SMS
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link'
                        id='kt_modal_share_email_tab'
                        data-bs-toggle='tab'
                        href='#kt_modal_share_email'
                        onClick={() => handleChange({target: {name: 'phone', value: '+27'}})}
                        role='tab'
                      >
                        Share By Email
                      </a>
                    </li>
                  </ul>
                  <div className='d-flex flex-column mb-10'>
                    <label className='form-label'>Recipient Name</label>
                    <Field
                      className='form-control'
                      placeholder='Recipient Name'
                      value={values.recipientName}
                      name='recipientName'
                    />
                  </div>

                  <div className='tab-content mb-10'>
                    <div
                      className='tab-pane fade show active'
                      id='kt_modal_share_sms'
                      role='tabpanel'
                    >
                      <div className='d-flex flex-column'>
                        <label className='form-label'>Enter a phone number below</label>
                        <PhoneField name='phone' />
                        {errors.phone && <div className='text-danger'>{errors.phone}</div>}
                      </div>
                    </div>
                    <div className='tab-pane fade' id='kt_modal_share_email' role='tabpanel'>
                      <div className='d-flex flex-column '>
                        <label className='form-label'>Enter an email address below</label>
                        <Field
                          type='email'
                          className='form-control'
                          placeholder='Enter email'
                          value={values.email}
                          name='email'
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {/* input group button */}
                    <SubmitButton
                      isSubmitting={isFormSubmitting}
                      isValid={isValid}
                      label='Send Ticket'
                      className='btn btn-primary mt-10 w-100'
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}
