/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'

import {KTIcon} from '../../../../_metronic/helpers'
import {Bar} from 'react-chartjs-2'
import {useAccountsUsersStore} from '../../../modules/settings/core/accountsUsersStore'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {formatMoney, formatNumber} from '../../../../_helpers/_helpers'
import {useEventsStore} from '../core/eventsStore'
import {useParams} from 'react-router-dom'

type Props = {
  className: string
  chartType: string | 'auto' | 'sales' | 'bookings'
  chartColor: string | 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info'
}

type ChartData = {
  labels: string[]
  datasets: {
    label?: string
    data: number[]
    fill: boolean
    borderColor: string
    borderWidth?: number
    backgroundColor?: string
    tension: number
  }[]
}

const EventSalesOrBookingsCard: FC<Props> = ({className, chartType, chartColor = 'success'}) => {
  const {bookingsLineData, currentEvent, salesLineData, getEventDashboard} = useEventsStore()
  const [chartHasData, setChartHasData] = useState(false)
  const [eventProgress, setEventProgress] = useState(0)
  const {selectedAccountsUsers} = useAccountsUsersStore()
  const {eventId} = useParams()
  const [chartData, setChartData] = useState<ChartData>({
    labels: [],
    datasets: [
      {
        data: [],
        fill: true,
        borderColor: getCSSVariableValue('--bs-success'),
        backgroundColor: getCSSVariableValue('--bs-success-light'),
        borderWidth: 1,
        tension: 0.4,
      },
    ],
  })

  useEffect(() => {
    if (!eventId) return

    if (bookingsLineData.ticketsCount === 0 && salesLineData.ticketsCount === 0) {
      getEventDashboard(eventId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // CHECK IF THERE'S DATA IN THE SALES CHART  IF NOT SET CHART TYPE TO BOOKINGS
    if (bookingsLineData.ticketsCount === 0 && salesLineData.ticketsCount === 0) return

    // calculate event progress (always in bookings)
    const eventProgress = Math.floor(
      (currentEvent.stats.bookingsTicketsCount / currentEvent.stats.ticketsTotalQty) * 100
    )
    setEventProgress(eventProgress)

    let loadSalesChart = false
    if (chartType === 'auto' && (bookingsLineData.ticketsCount || 0) > 0) {
      loadSalesChart = true
    } else {
      loadSalesChart = false
    }

    if (loadSalesChart === true) {
      // refreshChart()
      setChartData({
        labels: bookingsLineData.labels || [],
        datasets: [
          {
            data: bookingsLineData.series || [],
            fill: true,
            borderColor: getCSSVariableValue(`--bs-${chartColor}`),
            backgroundColor: getCSSVariableValue(`--bs-${chartColor}-light`),
            borderWidth: 1,
            tension: 0.4,
          },
        ],
      })
    } else {
      // refreshChart()
      setChartData({
        labels: bookingsLineData.labels || [],
        datasets: [
          {
            data: bookingsLineData.series || [],
            fill: true,
            borderColor: getCSSVariableValue(`--bs-${chartColor}`),
            backgroundColor: getCSSVariableValue(`--bs-${chartColor}-light`),
            borderWidth: 1,
            tension: 0.4,
          },
        ],
      })
    }

    if (currentEvent.stats.bookingsCount) setChartHasData(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingsLineData, salesLineData])

  return (
    <>
      {chartHasData ? (
        <>
          <div className={`card card-flush shadow-none border-1 ${className}`}>
            <div className='card-header pt-5 px-5'>
              <div className='card-title w-100 d-flex flex-column'>
                <div className='d-flex'>
                  <div className='event-progress d-flex flex-column border-end border-2 pe-0 me-3'>
                    <span className='display-1 display-lg-3 mb-n2 me-3 pb-0'>{eventProgress}%</span>
                  </div>
                  <div className='d-flex flex-column justify-content-center'>
                    {/* all time */}
                    <div className='d-flex fs-6 align-items-start'>
                      <span className='fw-bold text-dark me-2 '>
                        {chartType === 'sales'
                          ? formatMoney(
                              currentEvent.stats.transactionsAmount,
                              selectedAccountsUsers.account.currency,
                              0
                            )
                          : formatNumber(currentEvent.stats.bookingsTicketsCount, 0)}
                      </span>
                      <div className='fw-normal text-muted '>
                        {chartType === 'sales' ? 'Confirmed Sales' : 'Confirmed tickets'}
                      </div>
                    </div>

                    {/* this week */}
                    <div className='d-flex fs-6 align-items-start'>
                      <span className='fw-bold text-dark me-2 '>
                        {chartType === 'sales'
                          ? formatMoney(
                              currentEvent.stats.transactionsAmount,
                              selectedAccountsUsers.account.currency,
                              0
                            )
                          : formatNumber(bookingsLineData.ticketsCount, 0)}
                      </span>
                      <div className='fw-normal text-muted '>
                        {chartType === 'sales' ? 'Sales this week' : 'Tickets this week'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='px-2 pb-5 d-flex flex-column'>
              <div className='d-flex w-100'>
                <Bar
                  data={chartData}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        grid: {
                          display: false, // This will remove the grid lines for the x-axis
                          lineWidth: 0, // This will remove the line at the x-axis
                        },
                        border: {
                          display: true, // This will remove the border around the chart
                        },
                        ticks: {
                          display: false, // This will remove the tick labels for the x-axis
                        },
                      },
                      y: {
                        grid: {
                          display: false, // This will remove the grid lines for the y-axis
                          lineWidth: 0, // This will remove the line at the y-axis
                          drawOnChartArea: false, // This will remove the y-axis line
                        },
                        border: {
                          display: false, // This will remove the border around the chart
                        },
                        ticks: {
                          display: false, // This will remove the tick labels for the y-axis
                        },
                      },
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              </div>
              <div className='fw-normal text-center my-2 text-muted '>
                {chartType === 'sales' ? 'Sales this week' : 'Bookings this week'}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          className={`card card-flush border-dashed border-shadow-0 bg-transparent ${className}`}
        >
          <div className='card-body d-flex flex-center flex-column'>
            <KTIcon iconName='alert-circle' className='fs-2hx text-muted' />
            <span className='text-gray-400 fw-bold fs-3'>No Data Yet</span>
            <span className='text-gray-400 text-center fs-6'>
              Bookings and sales data will appear here.
            </span>
          </div>
        </div>
      )}
    </>
  )
}

export {EventSalesOrBookingsCard}
