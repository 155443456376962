import React, {FC, useState} from 'react'
import {GuestModel, GuestTicketModel, useGuestsStore} from '../core/guestsStore'
import {KTIcon} from '../../../../_metronic/helpers'
import {Modal} from 'react-bootstrap'
import clsx from 'clsx'

type Props = {
  guest: GuestModel
  ticket: GuestTicketModel
  onGuestCheckedIn?: (guest: GuestModel) => void
  checkpointFilter?: string | undefined
}

const GuestCheckInButton: FC<Props> = ({ticket, guest, checkpointFilter, onGuestCheckedIn}) => {
  const [show, setShow] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [checkError, setCheckError] = useState<string>('')
  const [currentCheckpointId, setCurrentCheckpointId] = useState<string>('')
  const {postCheckIn, setCurrentGuest, unsetCurrentGuest} = useGuestsStore()

  const handleCheckIn = async (checkpoint: any) => {
    try {
      setCurrentCheckpointId(checkpoint._id)
      setIsLoading(true)
      setCheckError('')
      const t: any = ticket
      await postCheckIn(t._id, checkpoint._id)

      onGuestCheckedIn?.(guest)
      handleCloseModal()
    } catch (err) {
      console.error(err)
      setCheckError('There was a problem checking in the guest')
    }

    setIsLoading(false)
  }

  const handleOpenModal = () => {
    setShow(true)
    setCurrentGuest(guest)
  }

  const handleCloseModal = () => {
    setShow(false)
    unsetCurrentGuest()
  }

  return (
    <>
      <button onClick={handleOpenModal} className='btn btn-sm btn-primary'>
        <KTIcon iconName='check-circle' iconType='outline' className='me-2' />
        Check In
      </button>

      {/* modal  */}

      <Modal show={show} className='modal-md' centered onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select a Checkpoint</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex flex-column p-lg-5 p-0'>
            <div className='d-flex justify-content-between'>
              {/* warning check-in cannot be reversed */}
              <div className='alert alert-secondary text-dark text-center w-100'>
                <span className='fw-bolder'>Warning:</span> Check-in cannot be reversed. Only click
                on the button below to confirm that the guest has arrived at the event.
              </div>
            </div>

            {ticket.ticket.checkpointSettings?.map((checkpointSetting: any, index) => {
              // 1. CHECK IF CHECKPOINT FILTER IS SET AND SKIP IF NOT MATCHING
              const skip = checkpointFilter && checkpointFilter !== checkpointSetting.checkpoint

              const checkpoint = ticket.ticket?.checkpoints?.find((cp: any) => {
                if (cp._id === checkpointSetting.checkpoint) {
                  return true
                } else {
                  return false
                }
              })

              // count checks where checkpoint is the same as the current checkpoint
              const checks = ticket.checks.filter(
                (check) => check.checkpoint === checkpointSetting.checkpoint
              ).length

              // if checkpoint is in checks
              const checked = checks < checkpointSetting.qty ? false : true

              return (
                <>
                  {!skip && (
                    <button
                      key={index}
                      disabled={isLoading || checked}
                      className={clsx(
                        'border fs-3 fw-bolder rounded mb-5 btn btn-color-white p-10',
                        {
                          'btn-danger': checked,
                          'btn-success': !checked,
                        }
                      )}
                      onClick={() => handleCheckIn(checkpoint)}
                    >
                      {isLoading && currentCheckpointId === checkpointSetting.checkpoint ? (
                        <>
                          <span className='spinner-border spinner-border-sm align-middle me-3'></span>
                          <span className=''>Just a sec</span>
                        </>
                      ) : (
                        <>
                          <KTIcon
                            iconName={checked ? 'cross-circle' : 'check-circle'}
                            className='me-2 fw-bolder fs-1'
                            iconType='outline'
                          />
                        </>
                      )}

                      {`${checkpoint?.name} (${checks}/${checkpointSetting.qty})`}
                    </button>
                  )}
                </>
              )
            })}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default GuestCheckInButton
