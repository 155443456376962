import clsx from 'clsx'
import {KTIcon} from '../../../helpers'
import {HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import {useAccountsUsersStore} from '../../../../app/modules/settings/core/accountsUsersStore'
import {useEventsStore} from '../../../../app/modules/events/core/eventsStore'
import {Link, useNavigate} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {Breadcrumbs} from '../../../../_components/SBPageTitle/Breadcrumbs'
import {useAppSettingsStore} from '../../../../app/modules/settings/core/appSettingsStore'
import {isMobileDevice} from '../../../assets/ts/_utils'

const itemClass = 'ms-1 ms-md-4'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const {selectedAccountsUsers, adminAccount, switchUserToAdmin, getCurrentAccount} =
    useAccountsUsersStore()
  const [imageExists, setImageExists] = useState(true)
  const {breadcrumbs, currentPage} = useAppSettingsStore()

  const {unsetCurrentEvent} = useEventsStore()
  const navigate = useNavigate()

  useEffect(() => {
    // MAKE SURE IMAGE URL IS NOT 404
    var img = new Image()
    img.src = selectedAccountsUsers.account.image || ''

    // if image is found
    img.onload = function () {
      setImageExists(true)
    }

    // if image is not found
    img.onerror = function () {
      setImageExists(false)
    }
  }, [selectedAccountsUsers])

  useEffect(() => {
    // MAKE SURE IMAGE URL IS NOT 404
    var img = new Image()
    img.src = adminAccount?.image || ''
  }, [adminAccount])

  const handleSwitchAccount = () => {
    // get account from search results
    switchUserToAdmin()
    navigate('/dashboard')
  }

  const {config} = useLayout()
  return (
    <div className='d-flex flex-row justify-content-between w-100 flex-grow-1'>
      {/* page details and pagination */}
      <div className='d-flex align-items-center me-2'>
        {!isMobileDevice() && (
          <div className='d-flex flex-column'>
            <h1 className='fs-2 fw-bolder text-dark mb-0'>{currentPage}</h1>
            <Breadcrumbs breadcrumbs={breadcrumbs} className='fs-7 text-gray-400' />
          </div>
        )}
      </div>

      {/* acccount nav */}
      <div className='app-navbar flex-shrink-0'>
        {getCurrentAccount()?.plan !== 'skybookings' && (
          <>
            <div className='app-navbar-item'>
              <Link
                id='kt_create_event'
                to='/events/create'
                className={clsx('btn btn-primary btn-sm btn-active-primary', {
                  'btn-icon': isMobileDevice(),
                })}
                onClick={unsetCurrentEvent}
              >
                <KTIcon iconName='plus-square' iconType='outline' className={btnIconClass} />
                {isMobileDevice() ? '' : 'Create Event'}
              </Link>
            </div>
          </>
        )}

        {/* 

      <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='element-plus' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

        {!adminAccount && getCurrentAccount()?.plan === 'skybookings' && <Search />}
        {adminAccount && (
          <>
            <div className={clsx('app-navbar-item', itemClass)}>
              <div
                onClick={handleSwitchAccount}
                className={clsx('cursor-pointer symbol', userAvatarClass)}
              >
                <span className='symbol-label bg-warning text-light fw-bolder'>
                  <KTIcon iconName='left' iconType='solid' className='text-dark fs-3' />
                </span>
              </div>
            </div>
          </>
        )}

        <div className={clsx('app-navbar-item', itemClass)}>
          <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-secondary btn-custom')} />
        </div>

        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            className={clsx('cursor-pointer symbol', userAvatarClass)}
            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            {imageExists === true ? (
              <img src={selectedAccountsUsers.account.image} alt='' />
            ) : (
              <span className='symbol-label bg-dark text-light fw-bolder'>
                {selectedAccountsUsers.account.name.charAt(0).toUpperCase()}
              </span>
            )}
          </div>
          <HeaderUserMenu />
        </div>

        {config.app?.header?.default?.menu?.display && (
          <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
            <div
              className='btn btn-icon btn-active-color-primary w-35px h-35px'
              id='kt_app_header_menu_toggle'
            >
              <KTIcon iconName='text-align-left' className={btnIconClass} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export {Navbar}
