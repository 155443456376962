/* eslint-disable jsx-a11y/anchor-is-valid */
import {useNavigate} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import {EventModel} from '../core/eventsStore'
import {useClientStore} from '../../clients/core/clientStore'

type Props = {
  event: EventModel
  onClick?: (option: string) => void
}

type Icon = {
  color: string
  label: string
  iconName: string
  link?: string
  target?: string
  option: string
  comingSoon?: boolean
  allowClient?: boolean
}

const EventOptions = ({event, onClick}: Props) => {
  const navigate = useNavigate()
  const {isClientLoggedIn} = useClientStore()

  const handleClick = (icon: Icon) => {
    if (icon.link) {
      if (icon.target) {
        window.open(icon.link, icon.target || '_self')
      } else {
        // navigate to the link
        navigate(icon.link)
      }
    }

    // emit option click
    if (onClick) {
      onClick(icon.option)
    }
  }

  const icons: Icon[] = [
    {
      color: 'dark',
      label: 'Bookings',
      iconName: 'abstract-28',
      link: `/events/${event.id}/bookings`,
      option: 'bookings',
      allowClient: true,
    },
    {
      color: 'dark',
      label: 'View Event',
      iconName: 'eye',
      link: `/event/${event.id}`,
      target: '_blank',
      option: 'view',
      allowClient: true,
    },
    {
      color: 'dark',
      label: 'Edit Event',
      iconName: 'wrench',
      link: `/events/${event.id}/edit`,
      option: 'edit',
      allowClient: false,
    },
    {
      color: 'danger',
      label: 'Delete',
      iconName: 'trash',
      option: 'delete',
      allowClient: false,
    },
    {
      color: 'dark',
      label: 'Guest List',
      iconName: 'user-tick',
      link: `/events/${event.id}/guests`,
      option: 'guests',
      allowClient: true,
    },
    {
      color: 'dark',
      label: 'Scan Tickets',
      iconName: 'scan-barcode',
      link: `/events/${event.id}/scanners`,
      option: 'scan',
      allowClient: false,
    },
    {
      color: 'dark',
      label: 'Analytics',
      iconName: 'chart-simple',
      link: `/events/${event.id}/analytics`,
      option: 'analytics',
      allowClient: true,
    },
    {
      color: 'info',
      label: 'Affiliates',
      iconName: 'people',
      link: '/events/create',
      option: 'affiliates',
      comingSoon: true,
      allowClient: false,
    },
    {
      color: 'warning',
      label: 'Clone Event',
      iconName: 'copy-success',
      link: '/events/create',
      option: 'clone',
      comingSoon: true,
      allowClient: false,
    },
    {
      color: 'dark',
      label: 'Point of Sale',
      iconName: 'handcart',
      link: `/events/${event.id}/pos`,
      option: 'pos',
      comingSoon: true,
      allowClient: false,
    },
  ]

  // show statements option if we are selling tickets
  if (event.sellTickets === true) {
    icons.push({
      color: 'dark',
      label: 'Statement',
      iconName: 'bank',
      link: `/events/${event.id}/statement`,
      option: 'statement',
      allowClient: true,
    })
  }

  // sort by comingSoon
  icons.sort((a, b) => {
    if (a.comingSoon && !b.comingSoon) {
      return 1
    }
    if (!a.comingSoon && b.comingSoon) {
      return -1
    }
    return 0
  })

  return (
    <div className={`d-flex flex-rows justify-content-center justify-content-lg-start flex-wrap`}>
      {icons
        .filter((icon) => {
          if (isClientLoggedIn) {
            return icon?.allowClient === true ? true : false
          }
          return true
        })
        .map((icon, index) => (
          <div key={index} className='d-flex m-3 w-90px h-90px w-lg-150px h-lg-150px p-0 card '>
            <button
              type='button'
              disabled={icon.comingSoon}
              onClick={() => handleClick(icon)}
              className='btn btn-icon btn-active-light border-hover border-secondary border-1 w-100 h-100'
            >
              {/* badge showing number of bookings on the top right */}

              <div className='d-flex flex-column justify-content-center align-items-center'>
                <KTIcon
                  className={`fs-1 text-${icon.comingSoon ? 'secondary' : icon.color} mb-3`}
                  iconType='outline'
                  iconName={icon.iconName}
                />
                <span className='fs-7'>{icon.label}</span>
                {/* {icon.label === 'Bookings' && (
                <div className='badge badge-light-danger badge-circle'>
                  {event.stats.bookingsCount}
                </div>
              )} */}
                {icon.comingSoon && (
                  <span className='text-muted d-block fs-7 mt-3'>Coming Soon</span>
                )}
              </div>
            </button>
          </div>
        ))}
    </div>
  )
}
export {EventOptions}
