/* eslint-disable jsx-a11y/anchor-is-valid */

import {useNavigate} from 'react-router-dom'

import {AccountsUsersModel, useAccountsUsersStore} from '../../settings/core/accountsUsersStore'
import {useAuthStore} from '../core/authStore'
import {useState} from 'react'

export function SelectAccount() {
  const navigate = useNavigate()
  // const {logout, accountsUsers, saveCurrentAccountsUsers, saveAuth} = useAuth()
  const {logout, selectAccount, retrieveRedirect, redirect} = useAuthStore()
  const {accountsUsers, getCurrentUser} = useAccountsUsersStore()
  const [isLoading, setIsLoading] = useState(false)
  const [currentAccountsUsersId, setCurrentAccountsUsersId] = useState<string | null>(null)
  const handleLogout = () => {
    logout()
    navigate('/auth')
  }

  const handleSelectAccount = async (au: AccountsUsersModel) => {
    // setup loading
    setIsLoading(true)
    setCurrentAccountsUsersId(au.id)

    // send the selected accountsUsersId to the server and get an updated token
    const accountSelected = await selectAccount(au.id)
    setIsLoading(false)
    setCurrentAccountsUsersId(null)

    if (accountSelected) {
      retrieveRedirect()

      // navigate(redirectTo)
    } else {
      console.log('there was an error selecting the account')
    }
  }

  return (
    <>
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Select Account</h1>
        <div className='text-gray-500 fw-semibold fs-6'>
          Select the account you want to work with
        </div>
      </div>
      {/* begin::Heading */}

      {/* IF USER IS ADMINISTRATOR */}
      {getCurrentUser()?.role === 'administrator' && (
        <div className='d-grid mb-10'>
          <button
            id='kt_sign_in_submit'
            className='btn btn-primary'
            onClick={() => navigate('/settings/accounts')}
          >
            Manage Accounts
          </button>
        </div>
      )}

      {/* begin:: list of accounts */}
      {accountsUsers &&
        accountsUsers.map((au: AccountsUsersModel) => {
          return (
            <div
              key={au.id}
              className='btn d-flex align-items-center btn-active-light-primary btn-outline rounded p-5 mb-3'
              onClick={() => handleSelectAccount(au)}
            >
              <span className='text-primary me-5'>
                {/* loading  */}
                {currentAccountsUsersId === au.id && isLoading ? (
                  <span className='spinner spinner-primary spinner-sm spinner-border ms-5'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </span>
                ) : (
                  <i className='ki-duotone ki-abstract-26 text-primary fs-1 me-5'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                )}
              </span>
              <div className='flex-grow-1 me-2 d-flex flex-column align-items-start'>
                <span className='fw-bold text-gray-800  fs-6'>{au.account.name}</span>
                <span className='text-muted fw-semibold d-block'>{au.role}</span>
              </div>
            </div>
          )
        })}

      {/* end:: list of account */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button id='kt_sign_in_submit' className='btn btn-danger' onClick={handleLogout}>
          Logout
        </button>
      </div>
      {/* end::Action */}
    </>
  )
}
