import {create, StateCreator} from 'zustand'
import {devtools} from 'zustand/middleware'

export type AppSettingsModel = {
  baseUrl: string
  apiUrl: string
  uploadsUrl: string
  fee: number
  gatewayFeePercentage: number
  gatewayFee: number
  version: string
  paystackPublicKey: string
  name: string
  vat: number
  sbPhone: string
  sbEmail: string
}

export type BreadcrumbModel = {
  object: string
  title: string
  link: string
}

export const initialAppSettings: AppSettingsModel = {
  baseUrl: process.env.REACT_APP_PUBLIC_URL || 'http://localhost:4000',
  apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:3000/v1',
  uploadsUrl: process.env.REACT_APP_API_URL + '/uploads' || 'http://localhost:3000/v1/uploads',
  fee: Number(process.env.REACT_APP_SB_COMMISSION_PERCENTAGE) || 5,
  gatewayFeePercentage: Number(process.env.REACT_APP_PAYSTACK_FEES_PERCENTAGE) || 2.9,
  gatewayFee: Number(process.env.REACT_APP_PAYSTACK_FEES) || 1,
  version: process.env.REACT_APP_VERSION || '2.0.0',
  paystackPublicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY || 'pk_test_1234567890',
  name: process.env.REACT_APP_THEME_NAME || 'skybookings',
  vat: Number(process.env.REACT_APP_VAT_PERCENTAGE) || 15,
  sbPhone: process.env.REACT_APP_SB_PHONE || '',
  sbEmail: process.env.REACT_APP_SB_EMAIL || '',
}

type AppSettingsStore = {
  appSettings: AppSettingsModel
  currentPage: string
  breadcrumbs: BreadcrumbModel[]
  setSettings: (appSettings: AppSettingsModel) => void
  getSettings: () => AppSettingsModel
  setCurrentPage: (currentPage: string) => void
  setBreadcrumbs: (breadCrumbs: BreadcrumbModel[]) => void
}

const createStore: StateCreator<AppSettingsStore> = (set, get) => ({
  appSettings: initialAppSettings,
  currentPage: '',
  breadcrumbs: [],
  setSettings: (appSettings: AppSettingsModel) => set({appSettings}),
  getSettings: () => get().appSettings,
  setCurrentPage: (currentPage: string) => set({currentPage}),
  setBreadcrumbs: (breadcrumbs: BreadcrumbModel[]) => set({breadcrumbs}),
})

export const appSettingsStore = create(devtools(createStore))
export const useAppSettingsStore = appSettingsStore
